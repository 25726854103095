// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL Reports API (nestjs)
 * API for reports in WL
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  SortType,
  StudentVocabularyAllTimeResponseDto,
  StudentVocabularyMonthResponseDto,
  StudentVocabularySortableFields,
  StudentVocabularyWeekResponseDto,
  WordWallsControllerGetStudentLanguageGem200Response,
  WordWallsControllerGetStudentNewWord200Response,
} from '../models/index'
import {
  SortTypeFromJSON,
  SortTypeToJSON,
  StudentVocabularyAllTimeResponseDtoFromJSON,
  StudentVocabularyAllTimeResponseDtoToJSON,
  StudentVocabularyMonthResponseDtoFromJSON,
  StudentVocabularyMonthResponseDtoToJSON,
  StudentVocabularySortableFieldsFromJSON,
  StudentVocabularySortableFieldsToJSON,
  StudentVocabularyWeekResponseDtoFromJSON,
  StudentVocabularyWeekResponseDtoToJSON,
  WordWallsControllerGetStudentLanguageGem200ResponseFromJSON,
  WordWallsControllerGetStudentLanguageGem200ResponseToJSON,
  WordWallsControllerGetStudentNewWord200ResponseFromJSON,
  WordWallsControllerGetStudentNewWord200ResponseToJSON,
} from '../models/index'

export interface WordWallsControllerGetStudentLanguageGemRequest {
  studentId: string
  skip?: number
  take?: number
}

export interface WordWallsControllerGetStudentLanguageGemCountRequest {
  studentId: string
  from: Date
  to: Date
}

export interface WordWallsControllerGetStudentNewWordRequest {
  studentId: string
  skip?: number
  take?: number
}

export interface WordWallsControllerGetStudentNewWordCountRequest {
  studentId: string
  from: Date
  to: Date
}

export interface WordWallsControllerGetStudentVocabularyAllTimeRequest {
  studentId: string
  sortBy?: StudentVocabularySortableFields
  sortType?: SortType
}

export interface WordWallsControllerGetStudentVocabularyMonthRequest {
  studentId: string
  date: Date
  sortBy?: StudentVocabularySortableFields
  sortType?: SortType
}

export interface WordWallsControllerGetStudentVocabularyWeekRequest {
  studentId: string
  date: Date
  sortBy?: StudentVocabularySortableFields
  sortType?: SortType
}

/**
 *
 */
export class WordwallsApi extends runtime.BaseAPI {
  /**
   */
  async wordWallsControllerGetStudentLanguageGemRaw(
    requestParameters: WordWallsControllerGetStudentLanguageGemRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<WordWallsControllerGetStudentLanguageGem200Response>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentLanguageGem.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/language_gems`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WordWallsControllerGetStudentLanguageGem200ResponseFromJSON(jsonValue),
    )
  }

  /**
   */
  async wordWallsControllerGetStudentLanguageGem(
    requestParameters: WordWallsControllerGetStudentLanguageGemRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WordWallsControllerGetStudentLanguageGem200Response> {
    const response = await this.wordWallsControllerGetStudentLanguageGemRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentLanguageGemCountRaw(
    requestParameters: WordWallsControllerGetStudentLanguageGemCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentLanguageGemCount.',
      )
    }

    if (
      requestParameters.from === null ||
      requestParameters.from === undefined
    ) {
      throw new runtime.RequiredError(
        'from',
        'Required parameter requestParameters.from was null or undefined when calling wordWallsControllerGetStudentLanguageGemCount.',
      )
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        'to',
        'Required parameter requestParameters.to was null or undefined when calling wordWallsControllerGetStudentLanguageGemCount.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/language_gem_count`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<number>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   */
  async wordWallsControllerGetStudentLanguageGemCount(
    requestParameters: WordWallsControllerGetStudentLanguageGemCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response =
      await this.wordWallsControllerGetStudentLanguageGemCountRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentNewWordRaw(
    requestParameters: WordWallsControllerGetStudentNewWordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<WordWallsControllerGetStudentNewWord200Response>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentNewWord.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/new_words`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WordWallsControllerGetStudentNewWord200ResponseFromJSON(jsonValue),
    )
  }

  /**
   */
  async wordWallsControllerGetStudentNewWord(
    requestParameters: WordWallsControllerGetStudentNewWordRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WordWallsControllerGetStudentNewWord200Response> {
    const response = await this.wordWallsControllerGetStudentNewWordRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentNewWordCountRaw(
    requestParameters: WordWallsControllerGetStudentNewWordCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentNewWordCount.',
      )
    }

    if (
      requestParameters.from === null ||
      requestParameters.from === undefined
    ) {
      throw new runtime.RequiredError(
        'from',
        'Required parameter requestParameters.from was null or undefined when calling wordWallsControllerGetStudentNewWordCount.',
      )
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        'to',
        'Required parameter requestParameters.to was null or undefined when calling wordWallsControllerGetStudentNewWordCount.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.from !== undefined) {
      queryParameters['from'] = (requestParameters.from as any).toISOString()
    }

    if (requestParameters.to !== undefined) {
      queryParameters['to'] = (requestParameters.to as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/new_word_count`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<number>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   */
  async wordWallsControllerGetStudentNewWordCount(
    requestParameters: WordWallsControllerGetStudentNewWordCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.wordWallsControllerGetStudentNewWordCountRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyAllTimeRaw(
    requestParameters: WordWallsControllerGetStudentVocabularyAllTimeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<StudentVocabularyAllTimeResponseDto>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentVocabularyAllTime.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/vocabulary`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(StudentVocabularyAllTimeResponseDtoFromJSON),
    )
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyAllTime(
    requestParameters: WordWallsControllerGetStudentVocabularyAllTimeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<StudentVocabularyAllTimeResponseDto>> {
    const response =
      await this.wordWallsControllerGetStudentVocabularyAllTimeRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyMonthRaw(
    requestParameters: WordWallsControllerGetStudentVocabularyMonthRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<StudentVocabularyMonthResponseDto>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentVocabularyMonth.',
      )
    }

    if (
      requestParameters.date === null ||
      requestParameters.date === undefined
    ) {
      throw new runtime.RequiredError(
        'date',
        'Required parameter requestParameters.date was null or undefined when calling wordWallsControllerGetStudentVocabularyMonth.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.date !== undefined) {
      queryParameters['date'] = (requestParameters.date as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/vocabulary_month`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(StudentVocabularyMonthResponseDtoFromJSON),
    )
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyMonth(
    requestParameters: WordWallsControllerGetStudentVocabularyMonthRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<StudentVocabularyMonthResponseDto>> {
    const response = await this.wordWallsControllerGetStudentVocabularyMonthRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyWeekRaw(
    requestParameters: WordWallsControllerGetStudentVocabularyWeekRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<StudentVocabularyWeekResponseDto>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling wordWallsControllerGetStudentVocabularyWeek.',
      )
    }

    if (
      requestParameters.date === null ||
      requestParameters.date === undefined
    ) {
      throw new runtime.RequiredError(
        'date',
        'Required parameter requestParameters.date was null or undefined when calling wordWallsControllerGetStudentVocabularyWeek.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sortBy !== undefined) {
      queryParameters['sortBy'] = requestParameters.sortBy
    }

    if (requestParameters.sortType !== undefined) {
      queryParameters['sortType'] = requestParameters.sortType
    }

    if (requestParameters.date !== undefined) {
      queryParameters['date'] = (requestParameters.date as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('bearer', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/wordwalls/students/{studentId}/vocabulary_week`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(StudentVocabularyWeekResponseDtoFromJSON),
    )
  }

  /**
   */
  async wordWallsControllerGetStudentVocabularyWeek(
    requestParameters: WordWallsControllerGetStudentVocabularyWeekRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<StudentVocabularyWeekResponseDto>> {
    const response = await this.wordWallsControllerGetStudentVocabularyWeekRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
