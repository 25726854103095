// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AssignmentStudentDataRepresentationBaseApiResult,
  AssignmentStudentDataRepresentationListBaseApiResult,
  StudentAssignmentProgress,
  StudentCurrentTaskRepresentationBaseApiResult,
  StudentInventoryPurchaseRepresentation,
  StudentProfileRepresentationBaseApiResult,
  StudentPurchaseRequest,
  StudentPurchasedItemRepresentationBaseApiListResult,
  TeacherRepresentationListBaseApiResult,
  UpdateStudentAvatarModel,
} from '../models/index'
import {
  AssignmentStudentDataRepresentationBaseApiResultFromJSON,
  AssignmentStudentDataRepresentationBaseApiResultToJSON,
  AssignmentStudentDataRepresentationListBaseApiResultFromJSON,
  AssignmentStudentDataRepresentationListBaseApiResultToJSON,
  StudentAssignmentProgressFromJSON,
  StudentAssignmentProgressToJSON,
  StudentCurrentTaskRepresentationBaseApiResultFromJSON,
  StudentCurrentTaskRepresentationBaseApiResultToJSON,
  StudentInventoryPurchaseRepresentationFromJSON,
  StudentInventoryPurchaseRepresentationToJSON,
  StudentProfileRepresentationBaseApiResultFromJSON,
  StudentProfileRepresentationBaseApiResultToJSON,
  StudentPurchaseRequestFromJSON,
  StudentPurchaseRequestToJSON,
  StudentPurchasedItemRepresentationBaseApiListResultFromJSON,
  StudentPurchasedItemRepresentationBaseApiListResultToJSON,
  TeacherRepresentationListBaseApiResultFromJSON,
  TeacherRepresentationListBaseApiResultToJSON,
  UpdateStudentAvatarModelFromJSON,
  UpdateStudentAvatarModelToJSON,
} from '../models/index'

export interface ApiV1StudentsStudentIdAssignmentsAssignmentIdGetRequest {
  studentId: string
  assignmentId: string
}

export interface ApiV1StudentsStudentIdAssignmentsGetRequest {
  studentId: string
  activityId?: string
  status?: Array<StudentAssignmentProgress>
  skip?: number
  take?: number
}

export interface ApiV1StudentsStudentIdAvatarPutRequest {
  studentId: string
  updateStudentAvatarModel?: UpdateStudentAvatarModel
}

export interface ApiV1StudentsStudentIdCurrenttaskGetRequest {
  studentId: string
}

export interface ApiV1StudentsStudentIdGetRequest {
  studentId: string
}

export interface ApiV1StudentsStudentIdPurchasesGetRequest {
  studentId: string
}

export interface ApiV1StudentsStudentIdPurchasesPostRequest {
  studentId: string
  studentPurchaseRequest?: StudentPurchaseRequest
}

export interface ApiV1StudentsStudentIdPurchasesPutRequest {
  studentId: string
  studentPurchaseRequest?: StudentPurchaseRequest
}

export interface ApiV1StudentsStudentIdTeachersGetRequest {
  studentId: string
}

/**
 *
 */
export class StudentsApi extends runtime.BaseAPI {
  /**
   * Get 1 Student Assignment Details (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1StudentsStudentIdAssignmentsAssignmentIdGetRaw(
    requestParameters: ApiV1StudentsStudentIdAssignmentsAssignmentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<AssignmentStudentDataRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdAssignmentsAssignmentIdGet.',
      )
    }

    if (
      requestParameters.assignmentId === null ||
      requestParameters.assignmentId === undefined
    ) {
      throw new runtime.RequiredError(
        'assignmentId',
        'Required parameter requestParameters.assignmentId was null or undefined when calling apiV1StudentsStudentIdAssignmentsAssignmentIdGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/assignments/{assignmentId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'assignmentId'}}`,
            encodeURIComponent(String(requestParameters.assignmentId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AssignmentStudentDataRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Get 1 Student Assignment Details (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1StudentsStudentIdAssignmentsAssignmentIdGet(
    requestParameters: ApiV1StudentsStudentIdAssignmentsAssignmentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AssignmentStudentDataRepresentationBaseApiResult> {
    const response =
      await this.apiV1StudentsStudentIdAssignmentsAssignmentIdGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Get Student\'s Assignments (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1StudentsStudentIdAssignmentsGetRaw(
    requestParameters: ApiV1StudentsStudentIdAssignmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<AssignmentStudentDataRepresentationListBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdAssignmentsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.activityId !== undefined) {
      queryParameters['activityId'] = requestParameters.activityId
    }

    if (requestParameters.status) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/assignments`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AssignmentStudentDataRepresentationListBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   * Get Student\'s Assignments (Auth policies: AnyRoleRequirement) (Auth policies: AnyRoleRequirement)
   */
  async apiV1StudentsStudentIdAssignmentsGet(
    requestParameters: ApiV1StudentsStudentIdAssignmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AssignmentStudentDataRepresentationListBaseApiResult> {
    const response = await this.apiV1StudentsStudentIdAssignmentsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdAvatarPutRaw(
    requestParameters: ApiV1StudentsStudentIdAvatarPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdAvatarPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/avatar`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateStudentAvatarModelToJSON(
          requestParameters.updateStudentAvatarModel,
        ),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdAvatarPut(
    requestParameters: ApiV1StudentsStudentIdAvatarPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.apiV1StudentsStudentIdAvatarPutRaw(
      requestParameters,
      initOverrides,
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdCurrenttaskGetRaw(
    requestParameters: ApiV1StudentsStudentIdCurrenttaskGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentCurrentTaskRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdCurrenttaskGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/currenttask`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentCurrentTaskRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdCurrenttaskGet(
    requestParameters: ApiV1StudentsStudentIdCurrenttaskGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentCurrentTaskRepresentationBaseApiResult> {
    const response = await this.apiV1StudentsStudentIdCurrenttaskGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdGetRaw(
    requestParameters: ApiV1StudentsStudentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentProfileRepresentationBaseApiResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentProfileRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdGet(
    requestParameters: ApiV1StudentsStudentIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentProfileRepresentationBaseApiResult> {
    const response = await this.apiV1StudentsStudentIdGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesGetRaw(
    requestParameters: ApiV1StudentsStudentIdPurchasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentPurchasedItemRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdPurchasesGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/purchases`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentPurchasedItemRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesGet(
    requestParameters: ApiV1StudentsStudentIdPurchasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentPurchasedItemRepresentationBaseApiListResult> {
    const response = await this.apiV1StudentsStudentIdPurchasesGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Purchase an item (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesPostRaw(
    requestParameters: ApiV1StudentsStudentIdPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentInventoryPurchaseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdPurchasesPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/purchases`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: StudentPurchaseRequestToJSON(
          requestParameters.studentPurchaseRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentInventoryPurchaseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Purchase an item (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesPost(
    requestParameters: ApiV1StudentsStudentIdPurchasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentInventoryPurchaseRepresentation> {
    const response = await this.apiV1StudentsStudentIdPurchasesPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * OBSOLETE: use the POST method above  TODO - update FE and remove this (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesPutRaw(
    requestParameters: ApiV1StudentsStudentIdPurchasesPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentInventoryPurchaseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdPurchasesPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/purchases`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: StudentPurchaseRequestToJSON(
          requestParameters.studentPurchaseRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentInventoryPurchaseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * OBSOLETE: use the POST method above  TODO - update FE and remove this (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdPurchasesPut(
    requestParameters: ApiV1StudentsStudentIdPurchasesPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentInventoryPurchaseRepresentation> {
    const response = await this.apiV1StudentsStudentIdPurchasesPutRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdTeachersGetRaw(
    requestParameters: ApiV1StudentsStudentIdTeachersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TeacherRepresentationListBaseApiResult>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1StudentsStudentIdTeachersGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/students/{studentId}/teachers`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TeacherRepresentationListBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: AnyRoleRequirement, StudentResourceRequirement) (Auth policies: AnyRoleRequirement, StudentResourceRequirement)
   */
  async apiV1StudentsStudentIdTeachersGet(
    requestParameters: ApiV1StudentsStudentIdTeachersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TeacherRepresentationListBaseApiResult> {
    const response = await this.apiV1StudentsStudentIdTeachersGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
