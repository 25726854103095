// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const AssignmentStatus = {
  Unassigned: 'Unassigned',
  Assigned: 'Assigned',
  Deleted: 'Deleted',
} as const
export type AssignmentStatus =
  (typeof AssignmentStatus)[keyof typeof AssignmentStatus]

export function AssignmentStatusFromJSON(json: any): AssignmentStatus {
  return AssignmentStatusFromJSONTyped(json, false)
}

export function AssignmentStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssignmentStatus {
  return json as AssignmentStatus
}

export function AssignmentStatusToJSON(value?: AssignmentStatus | null): any {
  return value as any
}
