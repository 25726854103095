// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  ActivityResultEventRequestRepresentation,
  ActivityResultResponseRepresentation,
  QuizResultEventRequestRepresentation,
  QuizResultResponseRepresentation,
  StudentLessonProgressionRepresentationBaseApiResult,
  StudentLessonProgressionRequest,
  StudentLessonResultsRepresentation,
  StudentLessonResultsRepresentationV2,
  StudentLessonWritingRepresentationBaseApiListResult,
  WritingResultEventRequestRepresentation,
  WritingResultResponseRepresentation,
} from '../models/index'
import {
  ActivityResultEventRequestRepresentationFromJSON,
  ActivityResultEventRequestRepresentationToJSON,
  ActivityResultResponseRepresentationFromJSON,
  ActivityResultResponseRepresentationToJSON,
  QuizResultEventRequestRepresentationFromJSON,
  QuizResultEventRequestRepresentationToJSON,
  QuizResultResponseRepresentationFromJSON,
  QuizResultResponseRepresentationToJSON,
  StudentLessonProgressionRepresentationBaseApiResultFromJSON,
  StudentLessonProgressionRepresentationBaseApiResultToJSON,
  StudentLessonProgressionRequestFromJSON,
  StudentLessonProgressionRequestToJSON,
  StudentLessonResultsRepresentationFromJSON,
  StudentLessonResultsRepresentationToJSON,
  StudentLessonResultsRepresentationV2FromJSON,
  StudentLessonResultsRepresentationV2ToJSON,
  StudentLessonWritingRepresentationBaseApiListResultFromJSON,
  StudentLessonWritingRepresentationBaseApiListResultToJSON,
  WritingResultEventRequestRepresentationFromJSON,
  WritingResultEventRequestRepresentationToJSON,
  WritingResultResponseRepresentationFromJSON,
  WritingResultResponseRepresentationToJSON,
} from '../models/index'

export interface ApiV1MyLessonsStudentIdActivitiesActivityIdResultsGetRequest {
  studentId: string
  activityId: string
  includeFailed?: boolean
  skip?: number
  take?: number
}

export interface ApiV1MyLessonsStudentIdActivityResultsPostRequest {
  studentId: string
  activityResultEventRequestRepresentation?: ActivityResultEventRequestRepresentation
}

export interface ApiV1MyLessonsStudentIdProgressionGetRequest {
  studentId: string
  assignmentId?: string
  assignmentTaskId?: number
}

export interface ApiV1MyLessonsStudentIdProgressionPutRequest {
  studentId: string
  studentLessonProgressionRequest?: StudentLessonProgressionRequest
}

export interface ApiV1MyLessonsStudentIdQuizResultsPostRequest {
  studentId: string
  quizResultEventRequestRepresentation?: QuizResultEventRequestRepresentation
}

export interface ApiV1MyLessonsStudentIdQuizResultsQueryActivityIdGetRequest {
  studentId: string
  activityId: string
  includeFailed?: boolean
  skip?: number
  take?: number
}

export interface ApiV1MyLessonsStudentIdWritingResultsPostRequest {
  studentId: string
  writingResultEventRequestRepresentation?: WritingResultEventRequestRepresentation
}

export interface ApiV1MyLessonsStudentIdWritingsGetRequest {
  studentId: string
  skip?: number
  take?: number
}

/**
 *
 */
export class LessonsApi extends runtime.BaseAPI {
  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdActivitiesActivityIdResultsGetRaw(
    requestParameters: ApiV1MyLessonsStudentIdActivitiesActivityIdResultsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentLessonResultsRepresentationV2>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdActivitiesActivityIdResultsGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1MyLessonsStudentIdActivitiesActivityIdResultsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.includeFailed !== undefined) {
      queryParameters['includeFailed'] = requestParameters.includeFailed
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/activities/{activityId}/results`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentLessonResultsRepresentationV2FromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdActivitiesActivityIdResultsGet(
    requestParameters: ApiV1MyLessonsStudentIdActivitiesActivityIdResultsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentLessonResultsRepresentationV2> {
    const response =
      await this.apiV1MyLessonsStudentIdActivitiesActivityIdResultsGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Request to complete an activity (including videos)
   * Record an activity being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdActivityResultsPostRaw(
    requestParameters: ApiV1MyLessonsStudentIdActivityResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivityResultResponseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdActivityResultsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/activity-results`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ActivityResultEventRequestRepresentationToJSON(
          requestParameters.activityResultEventRequestRepresentation,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivityResultResponseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Request to complete an activity (including videos)
   * Record an activity being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdActivityResultsPost(
    requestParameters: ApiV1MyLessonsStudentIdActivityResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivityResultResponseRepresentation> {
    const response = await this.apiV1MyLessonsStudentIdActivityResultsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdProgressionGetRaw(
    requestParameters: ApiV1MyLessonsStudentIdProgressionGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentLessonProgressionRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdProgressionGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.assignmentId !== undefined) {
      queryParameters['assignmentId'] = requestParameters.assignmentId
    }

    if (requestParameters.assignmentTaskId !== undefined) {
      queryParameters['assignmentTaskId'] = requestParameters.assignmentTaskId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/progression`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentLessonProgressionRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdProgressionGet(
    requestParameters: ApiV1MyLessonsStudentIdProgressionGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentLessonProgressionRepresentationBaseApiResult> {
    const response = await this.apiV1MyLessonsStudentIdProgressionGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdProgressionPutRaw(
    requestParameters: ApiV1MyLessonsStudentIdProgressionPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentLessonProgressionRepresentationBaseApiResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdProgressionPut.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/progression`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: StudentLessonProgressionRequestToJSON(
          requestParameters.studentLessonProgressionRequest,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentLessonProgressionRepresentationBaseApiResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdProgressionPut(
    requestParameters: ApiV1MyLessonsStudentIdProgressionPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentLessonProgressionRepresentationBaseApiResult> {
    const response = await this.apiV1MyLessonsStudentIdProgressionPutRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Request to complete a map-quiz, vocabulary-quiz or skills-quiz
   * Record a quiz being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdQuizResultsPostRaw(
    requestParameters: ApiV1MyLessonsStudentIdQuizResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<QuizResultResponseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdQuizResultsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/quiz-results`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: QuizResultEventRequestRepresentationToJSON(
          requestParameters.quizResultEventRequestRepresentation,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      QuizResultResponseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Request to complete a map-quiz, vocabulary-quiz or skills-quiz
   * Record a quiz being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdQuizResultsPost(
    requestParameters: ApiV1MyLessonsStudentIdQuizResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<QuizResultResponseRepresentation> {
    const response = await this.apiV1MyLessonsStudentIdQuizResultsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdQuizResultsQueryActivityIdGetRaw(
    requestParameters: ApiV1MyLessonsStudentIdQuizResultsQueryActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentLessonResultsRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdQuizResultsQueryActivityIdGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1MyLessonsStudentIdQuizResultsQueryActivityIdGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.includeFailed !== undefined) {
      queryParameters['includeFailed'] = requestParameters.includeFailed
    }

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/quiz-results-query/{activityId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentLessonResultsRepresentationFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdQuizResultsQueryActivityIdGet(
    requestParameters: ApiV1MyLessonsStudentIdQuizResultsQueryActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentLessonResultsRepresentation> {
    const response =
      await this.apiV1MyLessonsStudentIdQuizResultsQueryActivityIdGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Request to complete a short-write or long-write result
   * Record a writing activity being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdWritingResultsPostRaw(
    requestParameters: ApiV1MyLessonsStudentIdWritingResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<WritingResultResponseRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdWritingResultsPost.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json-patch+json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/writing-results`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: WritingResultEventRequestRepresentationToJSON(
          requestParameters.writingResultEventRequestRepresentation,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      WritingResultResponseRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Request to complete a short-write or long-write result
   * Record a writing activity being complete, and return results including reward data (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdWritingResultsPost(
    requestParameters: ApiV1MyLessonsStudentIdWritingResultsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<WritingResultResponseRepresentation> {
    const response = await this.apiV1MyLessonsStudentIdWritingResultsPostRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdWritingsGetRaw(
    requestParameters: ApiV1MyLessonsStudentIdWritingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<
    runtime.ApiResponse<StudentLessonWritingRepresentationBaseApiListResult>
  > {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1MyLessonsStudentIdWritingsGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.skip !== undefined) {
      queryParameters['skip'] = requestParameters.skip
    }

    if (requestParameters.take !== undefined) {
      queryParameters['take'] = requestParameters.take
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/my-lessons/{studentId}/writings`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentLessonWritingRepresentationBaseApiListResultFromJSON(jsonValue),
    )
  }

  /**
   *  (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement) (Auth policies: StudentResourceRequirement, StudentTokenRoleRequirement)
   */
  async apiV1MyLessonsStudentIdWritingsGet(
    requestParameters: ApiV1MyLessonsStudentIdWritingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentLessonWritingRepresentationBaseApiListResult> {
    const response = await this.apiV1MyLessonsStudentIdWritingsGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
