// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AssignmentStudentDataRepresentation } from './AssignmentStudentDataRepresentation'
import {
  AssignmentStudentDataRepresentationFromJSON,
  AssignmentStudentDataRepresentationFromJSONTyped,
  AssignmentStudentDataRepresentationToJSON,
} from './AssignmentStudentDataRepresentation'

/**
 *
 * @export
 * @interface AssignmentStudentDataRepresentationListBaseApiResult
 */
export interface AssignmentStudentDataRepresentationListBaseApiResult {
  /**
   *
   * @type {Array<AssignmentStudentDataRepresentation>}
   * @memberof AssignmentStudentDataRepresentationListBaseApiResult
   */
  data?: Array<AssignmentStudentDataRepresentation> | null
}

/**
 * Check if a given object implements the AssignmentStudentDataRepresentationListBaseApiResult interface.
 */
export function instanceOfAssignmentStudentDataRepresentationListBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function AssignmentStudentDataRepresentationListBaseApiResultFromJSON(
  json: any,
): AssignmentStudentDataRepresentationListBaseApiResult {
  return AssignmentStudentDataRepresentationListBaseApiResultFromJSONTyped(
    json,
    false,
  )
}

export function AssignmentStudentDataRepresentationListBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssignmentStudentDataRepresentationListBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : json['data'] === null
        ? null
        : (json['data'] as Array<any>).map(
            AssignmentStudentDataRepresentationFromJSON,
          ),
  }
}

export function AssignmentStudentDataRepresentationListBaseApiResultToJSON(
  value?: AssignmentStudentDataRepresentationListBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
          ? null
          : (value.data as Array<any>).map(
              AssignmentStudentDataRepresentationToJSON,
            ),
  }
}
