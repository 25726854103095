// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ActivityResultEventRequestRepresentation
 */
export interface ActivityResultEventRequestRepresentation {
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  assignmentId?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultEventRequestRepresentation
   */
  assignmentTaskId?: number | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultEventRequestRepresentation
   */
  coinsEarned?: number
  /**
   *
   * @type {Array<string>}
   * @memberof ActivityResultEventRequestRepresentation
   */
  rewardIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof ActivityResultEventRequestRepresentation
   */
  passCriteriaIds?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  contentDataName?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  activityName?: string | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  activityType?: string | null
  /**
   *
   * @type {boolean}
   * @memberof ActivityResultEventRequestRepresentation
   */
  isEndOfLessonEvent?: boolean | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultEventRequestRepresentation
   */
  eventId?: string | null
}

/**
 * Check if a given object implements the ActivityResultEventRequestRepresentation interface.
 */
export function instanceOfActivityResultEventRequestRepresentation(
  value: object,
): boolean {
  let isInstance = true
  isInstance = isInstance && 'activityId' in value

  return isInstance
}

export function ActivityResultEventRequestRepresentationFromJSON(
  json: any,
): ActivityResultEventRequestRepresentation {
  return ActivityResultEventRequestRepresentationFromJSONTyped(json, false)
}

export function ActivityResultEventRequestRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultEventRequestRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    activityId: json['activity_id'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    assignmentTaskId: !exists(json, 'assignment_task_id')
      ? undefined
      : json['assignment_task_id'],
    coinsEarned: !exists(json, 'coins_earned')
      ? undefined
      : json['coins_earned'],
    rewardIds: !exists(json, 'reward_ids') ? undefined : json['reward_ids'],
    passCriteriaIds: !exists(json, 'pass_criteria_ids')
      ? undefined
      : json['pass_criteria_ids'],
    contentDataName: !exists(json, 'content_data_name')
      ? undefined
      : json['content_data_name'],
    activityName: !exists(json, 'activity_name')
      ? undefined
      : json['activity_name'],
    activityType: !exists(json, 'activity_type')
      ? undefined
      : json['activity_type'],
    isEndOfLessonEvent: !exists(json, 'is_end_of_lesson_event')
      ? undefined
      : json['is_end_of_lesson_event'],
    eventId: !exists(json, 'eventId') ? undefined : json['eventId'],
  }
}

export function ActivityResultEventRequestRepresentationToJSON(
  value?: ActivityResultEventRequestRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    activity_id: value.activityId,
    assignment_id: value.assignmentId,
    assignment_task_id: value.assignmentTaskId,
    coins_earned: value.coinsEarned,
    reward_ids: value.rewardIds,
    pass_criteria_ids: value.passCriteriaIds,
    content_data_name: value.contentDataName,
    activity_name: value.activityName,
    activity_type: value.activityType,
    is_end_of_lesson_event: value.isEndOfLessonEvent,
    eventId: value.eventId,
  }
}
