// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AssignmentPlayerRepresentation } from './AssignmentPlayerRepresentation'
import {
  AssignmentPlayerRepresentationFromJSON,
  AssignmentPlayerRepresentationFromJSONTyped,
  AssignmentPlayerRepresentationToJSON,
} from './AssignmentPlayerRepresentation'
import type { AssignmentStatus } from './AssignmentStatus'
import {
  AssignmentStatusFromJSON,
  AssignmentStatusFromJSONTyped,
  AssignmentStatusToJSON,
} from './AssignmentStatus'
import type { SimpleActivityRepresentation } from './SimpleActivityRepresentation'
import {
  SimpleActivityRepresentationFromJSON,
  SimpleActivityRepresentationFromJSONTyped,
  SimpleActivityRepresentationToJSON,
} from './SimpleActivityRepresentation'
import type { StudentAssignmentProgress } from './StudentAssignmentProgress'
import {
  StudentAssignmentProgressFromJSON,
  StudentAssignmentProgressFromJSONTyped,
  StudentAssignmentProgressToJSON,
} from './StudentAssignmentProgress'

/**
 *
 * @export
 * @interface AssignmentStudentDataRepresentation
 */
export interface AssignmentStudentDataRepresentation {
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  assignmentStudentId?: string
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  studentId?: string
  /**
   *
   * @type {StudentAssignmentProgress}
   * @memberof AssignmentStudentDataRepresentation
   */
  studentAssignmentStatus?: StudentAssignmentProgress
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  latestActivityResultId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  latestActivityResultSnapshotId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  latestCompletedActivityResultSnapshotId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  latestActivityAnswerSnaphotId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  latestCompleteActivityAnswerSnaphotId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  assignmentId?: string
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  classroomId?: string
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  activityId?: string | null
  /**
   *
   * @type {AssignmentStatus}
   * @memberof AssignmentStudentDataRepresentation
   */
  status?: AssignmentStatus
  /**
   *
   * @type {number}
   * @memberof AssignmentStudentDataRepresentation
   */
  feedbackCount?: number
  /**
   *
   * @type {Date}
   * @memberof AssignmentStudentDataRepresentation
   */
  beginDate?: Date | null
  /**
   *
   * @type {Date}
   * @memberof AssignmentStudentDataRepresentation
   */
  endDate?: Date | null
  /**
   *
   * @type {Date}
   * @memberof AssignmentStudentDataRepresentation
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof AssignmentStudentDataRepresentation
   */
  updatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  createdBy?: string
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  updatedBy?: string | null
  /**
   *
   * @type {boolean}
   * @memberof AssignmentStudentDataRepresentation
   */
  isGroup?: boolean | null
  /**
   *
   * @type {string}
   * @memberof AssignmentStudentDataRepresentation
   */
  meta?: string | null
  /**
   *
   * @type {SimpleActivityRepresentation}
   * @memberof AssignmentStudentDataRepresentation
   */
  activity?: SimpleActivityRepresentation
  /**
   *
   * @type {Array<AssignmentPlayerRepresentation>}
   * @memberof AssignmentStudentDataRepresentation
   */
  students?: Array<AssignmentPlayerRepresentation> | null
}

/**
 * Check if a given object implements the AssignmentStudentDataRepresentation interface.
 */
export function instanceOfAssignmentStudentDataRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function AssignmentStudentDataRepresentationFromJSON(
  json: any,
): AssignmentStudentDataRepresentation {
  return AssignmentStudentDataRepresentationFromJSONTyped(json, false)
}

export function AssignmentStudentDataRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssignmentStudentDataRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    assignmentStudentId: !exists(json, 'assignment_student_id')
      ? undefined
      : json['assignment_student_id'],
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    studentAssignmentStatus: !exists(json, 'student_assignment_status')
      ? undefined
      : StudentAssignmentProgressFromJSON(json['student_assignment_status']),
    latestActivityResultId: !exists(json, 'latest_activity_result_id')
      ? undefined
      : json['latest_activity_result_id'],
    latestActivityResultSnapshotId: !exists(
      json,
      'latest_activity_result_snapshot_id',
    )
      ? undefined
      : json['latest_activity_result_snapshot_id'],
    latestCompletedActivityResultSnapshotId: !exists(
      json,
      'latest_completed_activity_result_snapshot_id',
    )
      ? undefined
      : json['latest_completed_activity_result_snapshot_id'],
    latestActivityAnswerSnaphotId: !exists(
      json,
      'latest_activity_answer_snaphot_id',
    )
      ? undefined
      : json['latest_activity_answer_snaphot_id'],
    latestCompleteActivityAnswerSnaphotId: !exists(
      json,
      'latest_complete_activity_answer_snaphot_id',
    )
      ? undefined
      : json['latest_complete_activity_answer_snaphot_id'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    classroomId: !exists(json, 'classroom_id')
      ? undefined
      : json['classroom_id'],
    activityId: !exists(json, 'activity_id') ? undefined : json['activity_id'],
    status: !exists(json, 'status')
      ? undefined
      : AssignmentStatusFromJSON(json['status']),
    feedbackCount: !exists(json, 'feedback_count')
      ? undefined
      : json['feedback_count'],
    beginDate: !exists(json, 'begin_date')
      ? undefined
      : json['begin_date'] === null
        ? null
        : new Date(json['begin_date']),
    endDate: !exists(json, 'end_date')
      ? undefined
      : json['end_date'] === null
        ? null
        : new Date(json['end_date']),
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    createdBy: !exists(json, 'created_by') ? undefined : json['created_by'],
    updatedBy: !exists(json, 'updated_by') ? undefined : json['updated_by'],
    isGroup: !exists(json, 'is_group') ? undefined : json['is_group'],
    meta: !exists(json, 'meta') ? undefined : json['meta'],
    activity: !exists(json, 'activity')
      ? undefined
      : SimpleActivityRepresentationFromJSON(json['activity']),
    students: !exists(json, 'students')
      ? undefined
      : json['students'] === null
        ? null
        : (json['students'] as Array<any>).map(
            AssignmentPlayerRepresentationFromJSON,
          ),
  }
}

export function AssignmentStudentDataRepresentationToJSON(
  value?: AssignmentStudentDataRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    assignment_student_id: value.assignmentStudentId,
    student_id: value.studentId,
    student_assignment_status: StudentAssignmentProgressToJSON(
      value.studentAssignmentStatus,
    ),
    latest_activity_result_id: value.latestActivityResultId,
    latest_activity_result_snapshot_id: value.latestActivityResultSnapshotId,
    latest_completed_activity_result_snapshot_id:
      value.latestCompletedActivityResultSnapshotId,
    latest_activity_answer_snaphot_id: value.latestActivityAnswerSnaphotId,
    latest_complete_activity_answer_snaphot_id:
      value.latestCompleteActivityAnswerSnaphotId,
    assignment_id: value.assignmentId,
    classroom_id: value.classroomId,
    activity_id: value.activityId,
    status: AssignmentStatusToJSON(value.status),
    feedback_count: value.feedbackCount,
    begin_date:
      value.beginDate === undefined
        ? undefined
        : value.beginDate === null
          ? null
          : value.beginDate.toISOString(),
    end_date:
      value.endDate === undefined
        ? undefined
        : value.endDate === null
          ? null
          : value.endDate.toISOString(),
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    is_group: value.isGroup,
    meta: value.meta,
    activity: SimpleActivityRepresentationToJSON(value.activity),
    students:
      value.students === undefined
        ? undefined
        : value.students === null
          ? null
          : (value.students as Array<any>).map(
              AssignmentPlayerRepresentationToJSON,
            ),
  }
}
