// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const StudentAssignmentProgress = {
  NotStarted: 'NotStarted',
  InProgress: 'InProgress',
  Complete: 'Complete',
} as const
export type StudentAssignmentProgress =
  (typeof StudentAssignmentProgress)[keyof typeof StudentAssignmentProgress]

export function StudentAssignmentProgressFromJSON(
  json: any,
): StudentAssignmentProgress {
  return StudentAssignmentProgressFromJSONTyped(json, false)
}

export function StudentAssignmentProgressFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): StudentAssignmentProgress {
  return json as StudentAssignmentProgress
}

export function StudentAssignmentProgressToJSON(
  value?: StudentAssignmentProgress | null,
): any {
  return value as any
}
