// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityResultSnapshotRepresentation } from './ActivityResultSnapshotRepresentation'
import {
  ActivityResultSnapshotRepresentationFromJSON,
  ActivityResultSnapshotRepresentationFromJSONTyped,
  ActivityResultSnapshotRepresentationToJSON,
} from './ActivityResultSnapshotRepresentation'

/**
 *
 * @export
 * @interface ActivityResultSnapshotRepresentationBaseApiResult
 */
export interface ActivityResultSnapshotRepresentationBaseApiResult {
  /**
   *
   * @type {ActivityResultSnapshotRepresentation}
   * @memberof ActivityResultSnapshotRepresentationBaseApiResult
   */
  data?: ActivityResultSnapshotRepresentation
}

/**
 * Check if a given object implements the ActivityResultSnapshotRepresentationBaseApiResult interface.
 */
export function instanceOfActivityResultSnapshotRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function ActivityResultSnapshotRepresentationBaseApiResultFromJSON(
  json: any,
): ActivityResultSnapshotRepresentationBaseApiResult {
  return ActivityResultSnapshotRepresentationBaseApiResultFromJSONTyped(
    json,
    false,
  )
}

export function ActivityResultSnapshotRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultSnapshotRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : ActivityResultSnapshotRepresentationFromJSON(json['data']),
  }
}

export function ActivityResultSnapshotRepresentationBaseApiResultToJSON(
  value?: ActivityResultSnapshotRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: ActivityResultSnapshotRepresentationToJSON(value.data),
  }
}
