// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AssignmentStatus } from './AssignmentStatus'
import {
  AssignmentStatusFromJSON,
  AssignmentStatusFromJSONTyped,
  AssignmentStatusToJSON,
} from './AssignmentStatus'
import type { StudentAssignmentProgress } from './StudentAssignmentProgress'
import {
  StudentAssignmentProgressFromJSON,
  StudentAssignmentProgressFromJSONTyped,
  StudentAssignmentProgressToJSON,
} from './StudentAssignmentProgress'

/**
 *
 * @export
 * @interface AssignmentPlayerRepresentation
 */
export interface AssignmentPlayerRepresentation {
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  assignmentStudentId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  firstName?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  lastName?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  dna?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  grade?: string | null
  /**
   *
   * @type {number}
   * @memberof AssignmentPlayerRepresentation
   */
  attempts?: number
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  studentId?: string
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  resultId?: string | null
  /**
   *
   * @type {AssignmentStatus}
   * @memberof AssignmentPlayerRepresentation
   */
  status?: AssignmentStatus
  /**
   *
   * @type {StudentAssignmentProgress}
   * @memberof AssignmentPlayerRepresentation
   */
  studentAssignmentStatus?: StudentAssignmentProgress
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  latestActivityResultSnapshotId?: string | null
  /**
   *
   * @type {string}
   * @memberof AssignmentPlayerRepresentation
   */
  latestPassingActivityResultSnapshotId?: string | null
  /**
   *
   * @type {Date}
   * @memberof AssignmentPlayerRepresentation
   */
  beginDate?: Date | null
  /**
   *
   * @type {Date}
   * @memberof AssignmentPlayerRepresentation
   */
  endDate?: Date | null
  /**
   *
   * @type {Date}
   * @memberof AssignmentPlayerRepresentation
   */
  lastSnapshotDate?: Date | null
}

/**
 * Check if a given object implements the AssignmentPlayerRepresentation interface.
 */
export function instanceOfAssignmentPlayerRepresentation(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function AssignmentPlayerRepresentationFromJSON(
  json: any,
): AssignmentPlayerRepresentation {
  return AssignmentPlayerRepresentationFromJSONTyped(json, false)
}

export function AssignmentPlayerRepresentationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssignmentPlayerRepresentation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    assignmentStudentId: !exists(json, 'assignment_student_id')
      ? undefined
      : json['assignment_student_id'],
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    dna: !exists(json, 'dna') ? undefined : json['dna'],
    grade: !exists(json, 'Grade') ? undefined : json['Grade'],
    attempts: !exists(json, 'attempts') ? undefined : json['attempts'],
    studentId: !exists(json, 'student_id') ? undefined : json['student_id'],
    resultId: !exists(json, 'result_id') ? undefined : json['result_id'],
    status: !exists(json, 'status')
      ? undefined
      : AssignmentStatusFromJSON(json['status']),
    studentAssignmentStatus: !exists(json, 'student_assignment_status')
      ? undefined
      : StudentAssignmentProgressFromJSON(json['student_assignment_status']),
    latestActivityResultSnapshotId: !exists(
      json,
      'latest_activity_result_snapshot_id',
    )
      ? undefined
      : json['latest_activity_result_snapshot_id'],
    latestPassingActivityResultSnapshotId: !exists(
      json,
      'latest_passing_activity_result_snapshot_id',
    )
      ? undefined
      : json['latest_passing_activity_result_snapshot_id'],
    beginDate: !exists(json, 'begin_date')
      ? undefined
      : json['begin_date'] === null
        ? null
        : new Date(json['begin_date']),
    endDate: !exists(json, 'end_date')
      ? undefined
      : json['end_date'] === null
        ? null
        : new Date(json['end_date']),
    lastSnapshotDate: !exists(json, 'last_snapshot_date')
      ? undefined
      : json['last_snapshot_date'] === null
        ? null
        : new Date(json['last_snapshot_date']),
  }
}

export function AssignmentPlayerRepresentationToJSON(
  value?: AssignmentPlayerRepresentation | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    assignment_student_id: value.assignmentStudentId,
    first_name: value.firstName,
    last_name: value.lastName,
    dna: value.dna,
    Grade: value.grade,
    attempts: value.attempts,
    student_id: value.studentId,
    result_id: value.resultId,
    status: AssignmentStatusToJSON(value.status),
    student_assignment_status: StudentAssignmentProgressToJSON(
      value.studentAssignmentStatus,
    ),
    latest_activity_result_snapshot_id: value.latestActivityResultSnapshotId,
    latest_passing_activity_result_snapshot_id:
      value.latestPassingActivityResultSnapshotId,
    begin_date:
      value.beginDate === undefined
        ? undefined
        : value.beginDate === null
          ? null
          : value.beginDate.toISOString(),
    end_date:
      value.endDate === undefined
        ? undefined
        : value.endDate === null
          ? null
          : value.endDate.toISOString(),
    last_snapshot_date:
      value.lastSnapshotDate === undefined
        ? undefined
        : value.lastSnapshotDate === null
          ? null
          : value.lastSnapshotDate.toISOString(),
  }
}
