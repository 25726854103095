// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  CoinLogRepresentation,
  StudentActivityCoinsRepresentation,
  StudentActivityCoinsRepresentationV2,
} from '../models/index'
import {
  CoinLogRepresentationFromJSON,
  CoinLogRepresentationToJSON,
  StudentActivityCoinsRepresentationFromJSON,
  StudentActivityCoinsRepresentationToJSON,
  StudentActivityCoinsRepresentationV2FromJSON,
  StudentActivityCoinsRepresentationV2ToJSON,
} from '../models/index'

export interface ApiV1CoinsStudentIdActivityActivityIdCoinsEarnedGetRequest {
  studentId: string
  activityId: string
}

export interface ApiV1CoinsStudentIdBalanceGetRequest {
  studentId: string
}

export interface ApiV1CoinsStudentIdCoinsEarnedActivityIdGetRequest {
  studentId: string
  activityId: string
}

export interface ApiV1CoinsStudentIdLogSourceEventIdGetRequest {
  studentId: string
  sourceEventId: string
  sourceEventTypes?: Array<string>
}

/**
 *
 */
export class CoinsApi extends runtime.BaseAPI {
  /**
   * Gets the coins earned for a particular activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGetRaw(
    requestParameters: ApiV1CoinsStudentIdActivityActivityIdCoinsEarnedGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentActivityCoinsRepresentationV2>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/coins/{studentId}/activity/{activityId}/coins_earned`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentActivityCoinsRepresentationV2FromJSON(jsonValue),
    )
  }

  /**
   * Gets the coins earned for a particular activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGet(
    requestParameters: ApiV1CoinsStudentIdActivityActivityIdCoinsEarnedGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentActivityCoinsRepresentationV2> {
    const response =
      await this.apiV1CoinsStudentIdActivityActivityIdCoinsEarnedGetRaw(
        requestParameters,
        initOverrides,
      )
    return await response.value()
  }

  /**
   * Returns the student\'s coin balance. (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdBalanceGetRaw(
    requestParameters: ApiV1CoinsStudentIdBalanceGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1CoinsStudentIdBalanceGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/coins/{studentId}/balance`.replace(
          `{${'studentId'}}`,
          encodeURIComponent(String(requestParameters.studentId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<number>(response)
    } else {
      return new runtime.TextApiResponse(response) as any
    }
  }

  /**
   * Returns the student\'s coin balance. (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdBalanceGet(
    requestParameters: ApiV1CoinsStudentIdBalanceGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.apiV1CoinsStudentIdBalanceGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Gets the coins earned for a particular activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdCoinsEarnedActivityIdGetRaw(
    requestParameters: ApiV1CoinsStudentIdCoinsEarnedActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StudentActivityCoinsRepresentation>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1CoinsStudentIdCoinsEarnedActivityIdGet.',
      )
    }

    if (
      requestParameters.activityId === null ||
      requestParameters.activityId === undefined
    ) {
      throw new runtime.RequiredError(
        'activityId',
        'Required parameter requestParameters.activityId was null or undefined when calling apiV1CoinsStudentIdCoinsEarnedActivityIdGet.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/coins/{studentId}/coins_earned/{activityId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'activityId'}}`,
            encodeURIComponent(String(requestParameters.activityId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StudentActivityCoinsRepresentationFromJSON(jsonValue),
    )
  }

  /**
   * Gets the coins earned for a particular activity (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdCoinsEarnedActivityIdGet(
    requestParameters: ApiV1CoinsStudentIdCoinsEarnedActivityIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StudentActivityCoinsRepresentation> {
    const response = await this.apiV1CoinsStudentIdCoinsEarnedActivityIdGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Returns the student\'s coin log for a sourceEventId. (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdLogSourceEventIdGetRaw(
    requestParameters: ApiV1CoinsStudentIdLogSourceEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<CoinLogRepresentation>>> {
    if (
      requestParameters.studentId === null ||
      requestParameters.studentId === undefined
    ) {
      throw new runtime.RequiredError(
        'studentId',
        'Required parameter requestParameters.studentId was null or undefined when calling apiV1CoinsStudentIdLogSourceEventIdGet.',
      )
    }

    if (
      requestParameters.sourceEventId === null ||
      requestParameters.sourceEventId === undefined
    ) {
      throw new runtime.RequiredError(
        'sourceEventId',
        'Required parameter requestParameters.sourceEventId was null or undefined when calling apiV1CoinsStudentIdLogSourceEventIdGet.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.sourceEventTypes) {
      queryParameters['sourceEventTypes'] = requestParameters.sourceEventTypes
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        this.configuration.apiKey('Authorization') // oauth2 authentication
    }

    const response = await this.request(
      {
        path: `/api/v1/coins/{studentId}/log/{sourceEventId}`
          .replace(
            `{${'studentId'}}`,
            encodeURIComponent(String(requestParameters.studentId)),
          )
          .replace(
            `{${'sourceEventId'}}`,
            encodeURIComponent(String(requestParameters.sourceEventId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(CoinLogRepresentationFromJSON),
    )
  }

  /**
   * Returns the student\'s coin log for a sourceEventId. (Auth policies: StudentResourceRequirement) (Auth policies: StudentResourceRequirement)
   */
  async apiV1CoinsStudentIdLogSourceEventIdGet(
    requestParameters: ApiV1CoinsStudentIdLogSourceEventIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<CoinLogRepresentation>> {
    const response = await this.apiV1CoinsStudentIdLogSourceEventIdGetRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
