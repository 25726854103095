// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ActivityAnswerViewModel } from './ActivityAnswerViewModel'
import {
  ActivityAnswerViewModelFromJSON,
  ActivityAnswerViewModelFromJSONTyped,
  ActivityAnswerViewModelToJSON,
} from './ActivityAnswerViewModel'
import type { ActivityFeedbackRepresentation } from './ActivityFeedbackRepresentation'
import {
  ActivityFeedbackRepresentationFromJSON,
  ActivityFeedbackRepresentationFromJSONTyped,
  ActivityFeedbackRepresentationToJSON,
} from './ActivityFeedbackRepresentation'
import type { WritingStatus } from './WritingStatus'
import {
  WritingStatusFromJSON,
  WritingStatusFromJSONTyped,
  WritingStatusToJSON,
} from './WritingStatus'

/**
 *
 * @export
 * @interface ActivityResultViewModel
 */
export interface ActivityResultViewModel {
  /**
   *
   * @type {Array<ActivityAnswerViewModel>}
   * @memberof ActivityResultViewModel
   */
  answers?: Array<ActivityAnswerViewModel> | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultViewModel
   */
  uniqueWords?: number | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultViewModel
   */
  resultId: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultViewModel
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultViewModel
   */
  activityId: string
  /**
   *
   * @type {string}
   * @memberof ActivityResultViewModel
   */
  assignmentId?: string | null
  /**
   *
   * @type {WritingStatus}
   * @memberof ActivityResultViewModel
   */
  status: WritingStatus
  /**
   *
   * @type {number}
   * @memberof ActivityResultViewModel
   */
  score?: number | null
  /**
   *
   * @type {Date}
   * @memberof ActivityResultViewModel
   */
  createdDate?: Date
  /**
   *
   * @type {Date}
   * @memberof ActivityResultViewModel
   */
  updatedDate?: Date | null
  /**
   *
   * @type {string}
   * @memberof ActivityResultViewModel
   */
  reflection?: string | null
  /**
   *
   * @type {number}
   * @memberof ActivityResultViewModel
   */
  words?: number | null
  /**
   *
   * @type {Array<ActivityFeedbackRepresentation>}
   * @memberof ActivityResultViewModel
   */
  resultFeedback?: Array<ActivityFeedbackRepresentation> | null
}

/**
 * Check if a given object implements the ActivityResultViewModel interface.
 */
export function instanceOfActivityResultViewModel(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'resultId' in value
  isInstance = isInstance && 'userId' in value
  isInstance = isInstance && 'activityId' in value
  isInstance = isInstance && 'status' in value

  return isInstance
}

export function ActivityResultViewModelFromJSON(
  json: any,
): ActivityResultViewModel {
  return ActivityResultViewModelFromJSONTyped(json, false)
}

export function ActivityResultViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ActivityResultViewModel {
  if (json === undefined || json === null) {
    return json
  }
  return {
    answers: !exists(json, 'answers')
      ? undefined
      : json['answers'] === null
        ? null
        : (json['answers'] as Array<any>).map(ActivityAnswerViewModelFromJSON),
    uniqueWords: !exists(json, 'unique_words')
      ? undefined
      : json['unique_words'],
    resultId: json['result_id'],
    userId: json['user_id'],
    activityId: json['activity_id'],
    assignmentId: !exists(json, 'assignment_id')
      ? undefined
      : json['assignment_id'],
    status: WritingStatusFromJSON(json['status']),
    score: !exists(json, 'score') ? undefined : json['score'],
    createdDate: !exists(json, 'created_date')
      ? undefined
      : new Date(json['created_date']),
    updatedDate: !exists(json, 'updated_date')
      ? undefined
      : json['updated_date'] === null
        ? null
        : new Date(json['updated_date']),
    reflection: !exists(json, 'reflection') ? undefined : json['reflection'],
    words: !exists(json, 'words') ? undefined : json['words'],
    resultFeedback: !exists(json, 'result_feedback')
      ? undefined
      : json['result_feedback'] === null
        ? null
        : (json['result_feedback'] as Array<any>).map(
            ActivityFeedbackRepresentationFromJSON,
          ),
  }
}

export function ActivityResultViewModelToJSON(
  value?: ActivityResultViewModel | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    answers:
      value.answers === undefined
        ? undefined
        : value.answers === null
          ? null
          : (value.answers as Array<any>).map(ActivityAnswerViewModelToJSON),
    unique_words: value.uniqueWords,
    result_id: value.resultId,
    user_id: value.userId,
    activity_id: value.activityId,
    assignment_id: value.assignmentId,
    status: WritingStatusToJSON(value.status),
    score: value.score,
    created_date:
      value.createdDate === undefined
        ? undefined
        : value.createdDate.toISOString(),
    updated_date:
      value.updatedDate === undefined
        ? undefined
        : value.updatedDate === null
          ? null
          : value.updatedDate.toISOString(),
    reflection: value.reflection,
    words: value.words,
    result_feedback:
      value.resultFeedback === undefined
        ? undefined
        : value.resultFeedback === null
          ? null
          : (value.resultFeedback as Array<any>).map(
              ActivityFeedbackRepresentationToJSON,
            ),
  }
}
