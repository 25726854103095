// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * WL.Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AssignmentStudentDataRepresentation } from './AssignmentStudentDataRepresentation'
import {
  AssignmentStudentDataRepresentationFromJSON,
  AssignmentStudentDataRepresentationFromJSONTyped,
  AssignmentStudentDataRepresentationToJSON,
} from './AssignmentStudentDataRepresentation'

/**
 *
 * @export
 * @interface AssignmentStudentDataRepresentationBaseApiResult
 */
export interface AssignmentStudentDataRepresentationBaseApiResult {
  /**
   *
   * @type {AssignmentStudentDataRepresentation}
   * @memberof AssignmentStudentDataRepresentationBaseApiResult
   */
  data?: AssignmentStudentDataRepresentation
}

/**
 * Check if a given object implements the AssignmentStudentDataRepresentationBaseApiResult interface.
 */
export function instanceOfAssignmentStudentDataRepresentationBaseApiResult(
  value: object,
): boolean {
  let isInstance = true

  return isInstance
}

export function AssignmentStudentDataRepresentationBaseApiResultFromJSON(
  json: any,
): AssignmentStudentDataRepresentationBaseApiResult {
  return AssignmentStudentDataRepresentationBaseApiResultFromJSONTyped(
    json,
    false,
  )
}

export function AssignmentStudentDataRepresentationBaseApiResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AssignmentStudentDataRepresentationBaseApiResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data')
      ? undefined
      : AssignmentStudentDataRepresentationFromJSON(json['data']),
  }
}

export function AssignmentStudentDataRepresentationBaseApiResultToJSON(
  value?: AssignmentStudentDataRepresentationBaseApiResult | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: AssignmentStudentDataRepresentationToJSON(value.data),
  }
}
